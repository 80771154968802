.event-details {
  @apply my-8 overflow-hidden rounded-md border sm:rounded-lg;
}

.event-header {
  @apply px-4 py-5 sm:px-6;
}

.event-header h3 {
  @apply text-base font-semibold leading-6;
}

.event-contents {
  @apply border-t border-gray-200;
}

.event-detail-item {
  @apply px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6;
}

.event-detail-item dt {
  @apply text-sm font-medium;
}

.event-detail-item dd {
  @apply mt-1 text-sm sm:col-span-2 sm:mt-0;
}

.event-actions {
  @apply px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6;
}

.event-links-list {
  @apply divide-y divide-gray-200 overflow-hidden rounded-md border border-gray-200;
}

.event-link {
  @apply flex items-center justify-between text-sm;
}

.event-link a {
  @apply flex flex-grow items-center px-4 py-3 font-medium leading-8 hover:underline;
}

.event-details .meetup {
  @apply flex flex-row bg-skin-accent text-white;
}

.event-details .meetup.is-past {
  @apply bg-neutral-900;
}

.event-link svg {
  @apply h-8 w-8 pr-2;
}

.event-link .map-icon {
  @apply fill-skin-accent pr-2;
}
