/* ===== Hero Section ===== */
#hero {
  @apply pb-6 pt-8;
}
#hero h1 {
  @apply my-4 inline-block text-3xl font-bold sm:my-8 sm:text-5xl;
}
#hero .rss-link {
  @apply mb-6;
}
#hero .rss-icon {
  @apply mb-2 h-6 w-6 scale-110 fill-skin-accent sm:mb-3 sm:scale-125;
}
#hero p {
  @apply my-2;
}
.social-wrapper {
  @apply mt-4 flex flex-col sm:flex-row sm:items-center;
}
.social-links {
  @apply mb-1 mr-2 whitespace-nowrap sm:mb-0;
}

/* ===== Featured & Recent Posts Sections ===== */
#recent-posts {
  @apply pb-6 pt-12;
}
#recent-posts h2 {
  @apply text-2xl font-semibold tracking-wide;
}
.all-posts-btn-wrapper {
  @apply my-8 text-center;
}
#featured {
  @apply py-4;
}
#featured h2 {
  @apply text-2xl font-semibold tracking-wide;
}
